<template>
  <div v-if="subscription" class="subscription-edit-page">
    <b-card
      header-bg-variant="white"
      header-class="bg-white d-flex justify-content-between align-items-center py-3"
      class="mb-3"
    >
      <template #header>
        <h5 class="mb-0">
          Subscription
        </h5>

        <div class="h5 mb-0">
          <subscription-status
            :status="subscription.status"
            pill
          />
        </div>
      </template>

      <b-row class="text-center">
        <b-col md="4">
          <div class="small text-muted">Donor</div>
          <div class="mt-2">
              {{ subscription.customer_first_name }} {{ subscription.customer_last_name}}
              <div class="small text-muted">{{ subscription.customer_email }}</div>
          </div>
        </b-col>

        <b-col md="4">
          <div class="small text-muted">Payment Method</div>
          <div class="mt-2">
            <payment-method :method="subscription.payment_method" />
          </div>
        </b-col>

        <b-col md="4">
          <div class="small text-muted">Amount</div>
          <div class="mt-2">
            <number :amount="subscription.amount" prepend-text="฿" />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Details -->
    <b-card
      header-class="bg-white"
      class="mb-3"
    >
      <template #header>
        <h6 class="mb-0 text-muted py-2">Details</h6>
      </template>

      <b-row>
        <b-col md="6">
          <div class="mb-2">
            <div class="small text-muted">Campaign</div>
            <router-link
              :to="{
                name: 'campaign-edit',
                params: { id: subscription.campaign.id }
              }"
              class="text-dark"
            >
              {{ subscription.campaign[`name_${currentLocale}`] }} <b-badge>{{ subscription.campaign.slug }}</b-badge>
            </router-link>
          </div>
          <div class="mb-2">
            <div class="small text-muted">Source Order ID</div>
            <router-link
              :to="{
                name: 'invoice-edit',
                params: { id: subscription.source_invoice.id }
              }"
              class="text-dark"
            >
              {{ subscription.source_invoice.order_id }}
            </router-link>
          </div>
          <div class="mb-2">
            <div class="small text-muted">Start Date</div>
            <date-time :date="new Date(subscription.start_date)" />
          </div>
          <div class="">
            <div class="small text-muted">End Date</div>
            <date-time
              v-if="subscription.end_date"
              :date="new Date(subscription.end_date)"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Donor Information -->
    <b-row>
      <b-col>
        <b-card
          header-class="bg-white"
          class="mb-3"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              <h6 class="mb-0 text-muted py-2">Donor Information</h6>
              <!-- <b-button variant="outline-primary" size="sm">Edit</b-button> -->
            </div>
          </template>

          <div class="mb-2">
            <div class="small text-muted">Name</div>
            <div>{{ subscription.customer_first_name }} {{ subscription.customer_last_name }}</div>
          </div>

          <div class="mb-2">
            <div class="small text-muted">Email</div>
            <div>{{ subscription.customer_email }}</div>
          </div>

          <div class="mb-2">
            <div class="small text-muted">Email Consent</div>
            <div>
              <b-icon-check
                v-if="subscription.customer_email_consent"
                color="green"
                font-scale="2"
              />
              <b-icon-x
                v-else
                color="red"
                font-scale="2"
              />
            </div>
          </div>

          <div class="mb-2">
            <div class="small text-muted">Phone Number</div>
            <div>{{ subscription.customer_phone }}</div>
          </div>

          <div class="">
            <div class="small text-muted">Phone Consent</div>
            <div>
              <b-icon-check
                v-if="subscription.customer_phone_consent"
                color="green"
                font-scale="2"
              />
              <b-icon-x
                v-else
                color="red"
                font-scale="2"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col>
        <b-card
          header-class="bg-white"
          class="mb-3"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              <h6 class="mb-0 text-muted py-2">Receipt Information</h6>
              <!-- <b-button variant="outline-primary" size="sm">Edit</b-button> -->
            </div>
          </template>

          <!-- Name -->
          <div
            v-if="subscription.receipt_corporate_name"
            class="mb-2"
          >
            <div class="small text-muted">Corporate Name</div>
            <div>{{ subscription.receipt_corporate_name }}</div>
          </div>
          <div
            v-else
            class="mb-2"
          >
            <div class="small text-muted">Name</div>
            <div>{{ subscription.receipt_first_name }} {{ subscription.receipt_last_name }}</div>
          </div>

          <!-- Tax ID -->
          <div
            v-if="subscription.receipt_corporate_tax_id"
            class="mb-2"
          >
            <div class="small text-muted">Corporate Tax ID</div>
            <div>{{ subscription.receipt_corporate_tax_id }}</div>
          </div>

          <!-- Request Hard Copy -->
          <div class="mb-2">
            <div class="small text-muted">Request Hard Copy</div>
            <div>
              <b-icon-check
                v-if="subscription.receipt_hard_copy"
                color="green"
                font-scale="2"
              />
              <b-icon-x
                v-else
                color="red"
                font-scale="2"
              />
            </div>
          </div>

          <!-- Shipping Address -->
          <div
            v-if="subscription.receipt_hard_copy"
          >
            <div class="small text-muted">Shipping Address</div>
            <div>{{ subscription.receipt_shipping_address1 }}</div>
            <div>{{ subscription.receipt_shipping_address2 }}</div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Payment History -->
    <b-card
      header-class="bg-white"
      body-class="px-0 py-0"
      class="mb-3"
    >
      <template #header>
        <h6 class="mb-0 text-muted py-2">Payment History</h6>
      </template>

      <table class="table table-invoice-item-list">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Payment Date</th>
            <th class="text-right">Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="invoice in subscription.invoices"
            :key="invoice.id"
            @click="onInvoiceRowClicked(invoice)"
            class="cursor-pointer"
          >
            <td>{{ invoice.order_id }}</td>
            <td>
              <date-time
                v-if="invoice.payment_timestamp"
                :date="new Date(invoice.payment_timestamp)"
              />
            </td>
            <td class="text-right">
              <number :amount="invoice.price" prepend-text="฿" />
            </td>
            <td>
              <transaction-status :status="invoice.status" />
            </td>
          </tr>

          <tr
            v-if="subscription.invoices.length === 0"
            class="text-center"
          >
            <td colspan="4" class="text-muted">No invoices found</td>
          </tr>
        </tbody>
      </table>
    </b-card>

  </div>
</template>

<script>
import {
  BIconCheck,
  BIconX
} from 'bootstrap-vue'
import DateTime from '../components/DateTime.vue'
import Number from '../components/Number.vue'
import PaymentMethod from '../components/PaymentMethod.vue'
import SubscriptionStatus from '../components/SubscriptionStatus.vue'
import TransactionStatus from '../components/TransactionStatus.vue'
import { TRANSACTION_STATUS_PAID } from '../constant'

export default {
  name: 'subscriptionEdit',

  components: {
    BIconCheck,
    BIconX,
    DateTime,
    Number,
    PaymentMethod,
    SubscriptionStatus,
    TransactionStatus
  },

  computed: {
    subscriptionId () {
      return this.$route.params.id
    },

    isPaid () {
      return this.subscription.status === TRANSACTION_STATUS_PAID
    }
  },

  data () {
    return {
      subscription: null
    }
  },

  created () {
    this.fetch()
  },

  methods: {
    async fetch () {
      try {
        const resp = await this.$subscriptions.get(this.subscriptionId)
        this.subscription = resp.data.data
      }
      catch (e) {
        console.error(e.message)
      }
    },

    onInvoiceRowClicked (invoice) {
      this.$router.push({
        name: 'invoice-edit',
        params: { id: invoice.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
